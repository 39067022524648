/**
 * Create the store with dynamic reducers
 * Wrapper in case of serverside rendering
 * Creating master reducer to hyderate
 */

import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction, Middleware } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import logger from 'redux-logger'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import ActionMiddleware from '../middleware'

const masterReducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
    if (action.type === HYDRATE) {
        const { payload } = action
        return {
            ...state,
            ...payload
        }
    } else {
        return rootReducer(state, action)
    }
}

export const configureAppStore = (initialState = {}) => {
    const middlewares: Middleware[] = []
    if (process.env.NEXT_PUBLIC_CT_ENV !== 'production' && process.env.NEXT_PUBLIC_CT_ENV !== 'beta') {
        // @ts-ignore
        middlewares.push(logger)
    }

    const store = configureStore({
        // TODO(@): Fix reducer type
        // @ts-ignore
        reducer: masterReducer,
        preloadedState: initialState,
        devTools:
            process.env.NEXT_PUBLIC_CT_ENV !== 'production' && process.env.NEXT_PUBLIC_CT_ENV !== 'beta'
                ? {
                      shouldHotReload: false
                  }
                : false,
        // TODO: @Saransh to fix this type
        // @ts-ignore
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares).concat(ActionMiddleware)
    })
    if (process.env.NEXT_PUBLIC_CT_ENV !== 'production' && process.env.NEXT_PUBLIC_CT_ENV !== 'beta' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }
    return store
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>

export const wrapper = createWrapper(configureAppStore)
export const store = configureAppStore()
